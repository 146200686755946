import React, { useEffect, useState, useRef } from 'react'
import { InputNumber, Input, Space, Button, Tooltip } from 'antd'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

const EditCell = ({ value, onSubmit, type, validationSchema }) => {
  const refEditableInput = useRef(null)
  const refEditableWrapper = useRef(null)
  const [isEdit, setIsEdit] = useState(false)
  const [changedValue, setChangedValue] = useState(value)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState('')

  const handleSubmit = async () => {
    try {
      if (value !== changedValue) {
        setIsSubmitting(true)
        await onSubmit(changedValue)
      }
      setIsSubmitting(false)
      setIsEdit(false)
    } catch (submitError) {
      setIsSubmitting(false)
    }
  }

  const handleClick = async () => {
    if (!validationSchema) {
      handleSubmit()
      return
    }

    try {
      await validationSchema.validate(changedValue || null)
      setError('')
      handleSubmit()
    } catch (validationError) {
      setError(validationError.message)
    }
  }

  useEffect(() => {
    if (refEditableInput.current && isEdit) {
      refEditableInput.current.focus()
    }
  }, [isEdit])

  useEffect(() => {
    let onClick = () => null
    if (refEditableWrapper.current) {
      const ref = refEditableWrapper.current

      onClick = ({ target }) => {
        if (!ref?.contains(target)) {
          setIsEdit(false)
          setError('')
        }
      }
      document.addEventListener('click', onClick)
    }

    return () => document.removeEventListener('click', onClick)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refEditableWrapper.current])

  return (
    <div ref={refEditableWrapper}>
      {isEdit && (
        <div className="editable-field" style={{ position: 'relative' }}>
          {type === 'number' && (
            <InputNumber
              ref={refEditableInput}
              type="number"
              size="small"
              min={0}
              max={2147483647}
              defaultValue={value}
              onChange={number => {
                setChangedValue(number)
              }}
              onFocus={() => {
                setChangedValue(value)
              }}
            />
          )}
          {type === 'input' && (
            <Tooltip
              title={error}
              visible={error.length}
              color="white"
              overlayClassName="tooltip-error"
            >
              <Input
                size="small"
                defaultValue={value}
                onChange={({ target: { value: dataValue } }) => {
                  setChangedValue(dataValue)
                }}
              />
            </Tooltip>
          )}
          <div
            className="save-options"
            style={{
              position: 'absolute',
              bottom: '-38px',
              background: 'white',
              right: '0',
              zIndex: 1,
              boxShadow: '0 3px 6px rgba(111,111,111,0.2)',
              borderRadius: '0 0 3px 3px',
              padding: '6px',
            }}
          >
            <Space>
              <Button
                type="primary"
                icon={<CheckOutlined />}
                size="small"
                disabled={isSubmitting}
                onClick={handleClick}
              />
              <Button
                icon={<CloseOutlined />}
                size="small"
                onClick={() => {
                  setIsEdit(false)
                  setError('')
                }}
              />
            </Space>
          </div>
        </div>
      )}
      {/* Show data as clickable cell */}
      <Button
        style={{
          width: '100%',
          height: '24px',
          padding: '1px 7px',
          textAlign: 'center',
          visibility: 'visible',
          display: !isEdit ? 'block' : 'none',
        }}
        type="button"
        onClick={() => {
          setIsEdit(true)
        }}
      >
        {value}
      </Button>
    </div>
  )
}

EditCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSubmit: PropTypes.func.isRequired,
  type: PropTypes.string,
  validationSchema: PropTypes.shape({ validate: PropTypes.func }),
}
EditCell.defaultProps = {
  value: null,
  type: 'number',
  validationSchema: null,
}
export default EditCell
