import React from 'react'
import { useSelector } from 'react-redux'
import {
  Row,
  Col,
  Space,
  Layout,
  Menu,
  Avatar,
  Dropdown,
  Typography,
} from 'antd'
import { DownOutlined, SearchOutlined } from '@ant-design/icons'
import { NavLink, useLocation, Link } from 'react-router-dom'

import { logoutUser } from '../../../state/modules/auth'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'
import { useUser } from '../../../providers/UserProvider'

const navMenu = [
  {
    name: 'CMS Cases',
    path: '/cases',
    permission: 'view_case',
  },
  {
    name: 'Search',
    path: '/search',
    permission: ['view_facility', 'view_doctor'],
  },
  {
    name: 'Facility Profiles',
    path: '/facility-profiles',
    permission: 'view_facilityprofile',
  },
  {
    name: 'Facility Profile Mergers',
    path: '/facility-profile-mergers',
    permission: 'view_merger',
  },
  {
    name: 'Administrative Clusters',
    path: '/clusters',
    permission: 'view_facilitycluster',
  },
  {
    name: 'Health Networks',
    path: '/health-networks',
    permission: 'view_healthnetwork',
  },
  {
    name: 'Reports',
    path: '/reports',
    permission: 'view_report',
  },
]

const Header = () => {
  const { pathname } = useLocation()
  const dispatchHttp = useDispatchHttp()
  const { firstname, lastname } = useSelector(state => state.auth)
  const { isUserActionAllowed } = useUser()
  const allowedNavMenu = navMenu.filter(
    item => isUserActionAllowed(item.permission) || !item.permission
  )

  const handleLogout = () => {
    dispatchHttp(logoutUser())
  }

  return (
    <Layout.Header className="header">
      <Row justify="space-between" wrap={false}>
        <Col>
          <Link to="/">
            <Space align="start">
              <span className="logo">JaiaSearch</span>
              <Avatar
                icon={<SearchOutlined />}
                shape="square"
                size="small"
                className="logo__icon"
              />
            </Space>
          </Link>
        </Col>
        <Col>
          <Menu
            theme="light"
            mode="horizontal"
            selectedKeys={[pathname]}
            selectable
            data-testid="navigation-menu"
          >
            {allowedNavMenu?.map(({ path, name }) => (
              <Menu.Item key={path}>
                <NavLink to={path} key={path}>
                  {name}
                </NavLink>
              </Menu.Item>
            ))}
          </Menu>
        </Col>
        <Col>
          <Dropdown
            overlay={
              <Menu>
                {isUserActionAllowed('view_user') && (
                  <Menu.Item>
                    <Link to="/users">User List</Link>
                  </Menu.Item>
                )}
                {(isUserActionAllowed('view_version') ||
                  isUserActionAllowed('view_history_case')) && (
                  <Menu.Item>
                    <Link to="/user-activity-log">Activity Log</Link>
                  </Menu.Item>
                )}
                {isUserActionAllowed('view_importlog') && (
                  <Menu.Item>
                    <Link to="/data-sync-log">Sync Log</Link>
                  </Menu.Item>
                )}
                {isUserActionAllowed('view_group') && (
                  <Menu.Item>
                    <Link to="/roles">Roles</Link>
                  </Menu.Item>
                )}
                {(isUserActionAllowed('view_agency') ||
                  isUserActionAllowed('view_insurancecompany')) && (
                  <Menu.Item>
                    <Link to="/organizations">Organizations</Link>
                  </Menu.Item>
                )}
                {isUserActionAllowed('view_assignmentcopycompany') && (
                  <Menu.Item>
                    <Link to="/copy-company-assignment">
                      Copy Company Assignment
                    </Link>
                  </Menu.Item>
                )}
                {isUserActionAllowed('manage_retrievers') && (
                  <Menu.Item>
                    <Link to="/retrievers-list">Retrievers</Link>
                  </Menu.Item>
                )}
                {isUserActionAllowed('can_manage_vendor_relations') && (
                  <Menu.Item>
                    <Link to="/vendors">Vendors</Link>
                  </Menu.Item>
                )}
                {isUserActionAllowed('is_superuser') && (
                  <Menu.Item>
                    <Link to="/mass-dispatch-settings">
                      Mass Dispatch Settings
                    </Link>
                  </Menu.Item>
                )}
                <Menu.Item onClick={handleLogout}>Logout</Menu.Item>
              </Menu>
            }
          >
            <Space size="small" direction="horizontal">
              <Avatar>U</Avatar>
              <Typography.Text ellipsis className="header__user-dropdown">
                {firstname} {lastname}
              </Typography.Text>
              <DownOutlined />
            </Space>
          </Dropdown>
        </Col>
      </Row>
    </Layout.Header>
  )
}

export default Header
