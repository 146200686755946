import Api from './index'
import { API_URL } from '../constants'

class MassDispatch extends Api {
  getSettings(id, params) {
    return this.get('/', { params })
  }

  updateSetting(id, data) {
    return this.patch(`/${id}/`, { data })
  }
}

export default new MassDispatch({
  baseURL: `${API_URL}mass_dispatch_settings/`,
})
