import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import {
  Col,
  Form,
  Input,
  Row,
  Spin,
  Select,
  DatePicker,
  Tag,
  Typography,
  TimePicker,
} from 'antd'

import { useUser } from '../../../providers/UserProvider'
import PageLayout from '../../layouts/DetailPageLayout'
import Table from '../../components/Tables/SearchTable'
import EditCell from '../../components/Tables/EditCell'
import { retrieverStateOptions } from '../../../data/selectOptions'

import {
  getRetrievers,
  updateRetriever,
  selectAllRetrievers,
  getRetrieversTimeZones,
  selectAllRetrieversTimeZones,
  resetState,
} from '../../../state/modules/retrievers'
import useDispatchHttp from '../../../hooks/dispatchHttpHandler'

import {
  RETRIEVERS,
  DATE_FORMAT,
  TIME_FORMAT,
  DEFAULT_FACILITY_PROFILE_PAGE_SIZE,
  DEFAULT_FACILITY_PROFILE_PAGE_SIZE_OPTIONS,
} from '../../../constants'
import useDebounce from '../../../hooks/useDebounce'
import { validateSearchParams } from '../../../utils/functions'

const getSupervisorIDs = supervisors => supervisors.map(item => item.cms_id)

const getDefaultParams = userProfile => {
  let params = {
    available_aps: true,
  }

  if (!userProfile.is_superuser && userProfile.supervisors.length) {
    params = {
      ...params,
      supervisor_cms_id: getSupervisorIDs(userProfile.supervisors)?.toString(),
    }
  }

  return params
}

const RetrieversList = () => {
  const dispatchHttp = useDispatchHttp()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { getUserProfile, isUserActionAllowed } = useUser()
  const userProfile = getUserProfile()
  const retrieversList = useSelector(selectAllRetrievers)
  const retrieverTimeZones = useSelector(selectAllRetrieversTimeZones)
  const { count } = useSelector(state => state.retrievers)
  const loadingStatus = useSelector(state => state.retrievers.status)
  const [searchParams, setSearchParams] = useState(
    getDefaultParams(userProfile)
  )
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 15,
  })
  const [timeZonesOptions, setTimeZonesOptions] = useState([])

  const handleChangeValue = async ({ value, name, retrieverId }) => {
    await dispatchHttp(
      updateRetriever({
        id: retrieverId,
        data: { [name]: value },
      }),
      'Retriever updated successfully'
    )
    return dispatchHttp(
      getRetrievers({
        ...pagination,
        ...searchParams,
      })
    )
  }

  const tableColumns = [
    {
      title: 'Name',
      key: 'fullname',
      width: 160,
    },
    {
      title: 'Contname',
      key: 'contname',
      width: 180,
    },
    {
      title: 'CMS ID',
      key: 'cms_id',
      align: 'center',
      width: 80,
    },
    {
      title: 'Time Zone',
      key: 'timezone',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: (text, col, retriever) => {
        if (!isUserActionAllowed('manage_retrievers')) {
          return text?.map(timezone => <div key={timezone}>{timezone}</div>)
        }
        const values = text?.map(item => {
          const timezone = timeZonesOptions?.find(time => time.label === item)
          return timezone?.value
        })

        return (
          <Select
            size="small"
            mode="multiple"
            defaultValue={values || []}
            style={{ width: 86 }}
            name="timezone"
            options={timeZonesOptions}
            onChange={selectedOption => {
              handleChangeValue({
                value: selectedOption,
                name: 'timezone',
                retrieverId: retriever.id,
              })
            }}
          />
        )
      },
      width: 90,
    },
    {
      title: 'End Time',
      key: 'mass_dispatch_selection_end_time',
      align: 'center',
      // eslint-disable-next-line react/display-name
      render: (text, col, retriever) => {
        const format = TIME_FORMAT.full
        let date = null
        if (text) {
          date = moment(text, format)
        }
        return (
          <TimePicker
            style={{ width: '70px' }}
            defaultValue={date}
            format={format}
            disabled={!isUserActionAllowed('manage_retrievers')}
            allowClear={false}
            size="small"
            onChange={value => {
              handleChangeValue({
                value: value.format(format),
                name: 'mass_dispatch_selection_end_time',
                retrieverId: retriever.id,
              })
            }}
          />
        )
      },
      width: 86,
    },
    {
      title: 'APS',
      className: 'aps-column-background',
      children: [
        {
          title: 'Per Day',
          key: 'cases_per_day_target_count_aps',
          align: 'center',
          sorter: true,
          width: 100,
          className: 'aps-column-background',
          // eslint-disable-next-line react/display-name
          render: (text, col, retriever) => {
            if (!isUserActionAllowed('manage_retrievers')) {
              return text
            }

            return (
              <div>
                <EditCell
                  value={text}
                  onSubmit={data =>
                    handleChangeValue({
                      value: data,
                      name: 'cases_per_day_target_count_aps',
                      retrieverId: retriever.id,
                    })
                  }
                />
              </div>
            )
          },
        },
        {
          title: 'MD Daily',
          key: 'mass_dispatch_daily_aps_case_count',
          align: 'center',
          width: 60,
          className: 'aps-column-background',
        },
        {
          title: 'Limit',
          key: 'case_limit_aps',
          align: 'center',
          sorter: true,
          width: 88,
          className: 'aps-column-background',
          // eslint-disable-next-line react/display-name
          render: (text, col, retriever) => {
            if (!isUserActionAllowed('manage_retrievers')) {
              return text
            }

            return (
              <div>
                <EditCell
                  value={text}
                  onSubmit={data =>
                    handleChangeValue({
                      value: data,
                      name: 'case_limit_aps',
                      retrieverId: retriever.id,
                    })
                  }
                />
              </div>
            )
          },
        },
        {
          title: 'Assigned',
          key: 'assigned_aps_case_count',
          align: 'center',
          width: 80,
          className: 'aps-column-background',
        },
      ],
    },
    {
      title: 'ODR',
      className: 'odr-column-background',
      children: [
        {
          title: 'Per Day',
          key: 'cases_per_day_target_count_odr',
          align: 'center',
          sorter: true,
          width: 100,
          className: 'odr-column-background',
          // eslint-disable-next-line react/display-name
          render: (text, col, retriever) => {
            if (!isUserActionAllowed('manage_retrievers')) {
              return text
            }

            return (
              <div>
                <EditCell
                  value={text}
                  onSubmit={data =>
                    handleChangeValue({
                      value: data,
                      name: 'cases_per_day_target_count_odr',
                      retrieverId: retriever.id,
                    })
                  }
                />
              </div>
            )
          },
        },
        {
          title: 'MD Daily',
          key: 'mass_dispatch_daily_odr_case_count',
          align: 'center',
          width: 60,
          className: 'odr-column-background',
        },
        {
          title: 'Limit',
          key: 'case_limit_odr',
          align: 'center',
          sorter: true,
          width: 88,
          className: 'odr-column-background',
          // eslint-disable-next-line react/display-name
          render: (text, col, retriever) => {
            if (!isUserActionAllowed('manage_retrievers')) {
              return text
            }

            return (
              <div>
                <EditCell
                  value={text}
                  onSubmit={data =>
                    handleChangeValue({
                      value: data,
                      name: 'case_limit_odr',
                      retrieverId: retriever.id,
                    })
                  }
                />
              </div>
            )
          },
        },
        {
          title: 'Assigned',
          key: 'assigned_odr_case_count',
          align: 'center',
          width: 80,
          className: 'odr-column-background',
        },
      ],
    },
    ...(userProfile.is_superuser
      ? [
          {
            title: 'System Screen',
            key: 'is_system_screen',
            align: 'center',
            // eslint-disable-next-line react/display-name
            render: (text, col, retriever) => (
              <Select
                size="small"
                defaultValue={text}
                style={{ width: 70 }}
                name="is_system_screen"
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]}
                onChange={selectedOption =>
                  handleChangeValue({
                    value: selectedOption,
                    name: 'is_system_screen',
                    retrieverId: retriever.id,
                  })
                }
              />
            ),
            width: 75,
          },
        ]
      : []),
    {
      title: 'Away Until',
      key: 'away_until',
      align: 'center',
      width: 122,
      // eslint-disable-next-line react/display-name
      render: (text, col, retriever) => {
        let date = null
        if (text) {
          date = moment(text)
        }

        return (
          <DatePicker
            style={{ width: '106px' }}
            size="small"
            format={DATE_FORMAT.month_day_year}
            defaultValue={date}
            disabled={!isUserActionAllowed('manage_retrievers')}
            onChange={value => {
              handleChangeValue({
                value: value ? value.format(DATE_FORMAT.year_month_day) : null,
                name: 'away_until',
                retrieverId: retriever.id,
              })
            }}
            allowClear
          />
        )
      },
    },
    {
      title: 'CMS Statuses',
      key: 'cms_statuses',
      width: 112,
      // eslint-disable-next-line react/display-name
      render: (text, col, retriever) => {
        const isInactive = retriever.is_inactive
        const isForbidden = retriever.is_forbidden
        const fridayOff = retriever.is_no_work_fridays
        const retired = retriever.is_retired
        const getTag = name => <Tag color="red">{name}</Tag>

        return (
          <div style={{ whiteSpace: 'nowrap' }}>
            {(() => {
              if (retired) return retired && getTag('Retired')
              return (
                <>
                  {isInactive && getTag('Inactive')}
                  {isForbidden && getTag('Forbidden')}
                  {fridayOff && getTag('Friday Off')}
                </>
              )
            })()}
          </div>
        )
      },
    },
  ]

  const handleChangeSearch = useDebounce(e => {
    const { value } = e.target
    setSearchParams({ ...searchParams, search: value.trim() })
    dispatchHttp(
      getRetrievers({
        ...validateSearchParams(searchParams),
        ...pagination,
        search: value.trim(),
      })
    )
  }, 500)

  const handleChangeSupervisorID = useDebounce(e => {
    const { value } = e.target
    setSearchParams({ ...searchParams, supervisor_cms_id: value.trim() })
    dispatchHttp(
      getRetrievers({
        ...validateSearchParams(searchParams),
        ...pagination,
        supervisor_cms_id: value.trim(),
      })
    )
  }, 500)

  const handleChangFilter = state => {
    const supervisor = searchParams.supervisor_cms_id
      ? { supervisor_cms_id: searchParams.supervisor_cms_id }
      : {}
    const search = searchParams.search ? { search: searchParams.search } : {}

    const newSearchParams = { ...supervisor, ...search }

    const newState = state === 'all' ? { state } : { [state]: true }

    setSearchParams({ ...newSearchParams, ...newState })

    dispatchHttp(
      getRetrievers({
        ...newSearchParams,
        ...pagination,
        ...validateSearchParams({ ...newState }),
      })
    )
  }

  useEffect(() => {
    setPagination({ page: 1, pageSize: 15 })
  }, [count])

  useEffect(() => {
    const data = retrieverTimeZones.map(timeZone => ({
      label: timeZone.name,
      value: timeZone.id,
    }))
    setTimeZonesOptions(data)
  }, [retrieverTimeZones])

  useEffect(() => {
    async function fetchData() {
      if (isUserActionAllowed('manage_retrievers')) {
        await dispatch(getRetrieversTimeZones())
      }
      dispatchHttp(getRetrievers({ ...searchParams, ...pagination }))
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchHttp])

  useEffect(
    () => () => {
      dispatch(resetState())
    },
    [dispatch]
  )

  return (
    <Spin spinning={false}>
      <PageLayout>
        <>
          <Row
            gutter={[16, 32]}
            style={{
              background: '#fff',
              padding: '10px 12px',
              alignItems: 'center',
              justifyContent: 'space-between',
              margin: 0,
            }}
          >
            <Col>
              <Typography.Title level={4} style={{ marginBottom: 0 }}>
                Retrievers <span className="title-badge">{count}</span>
              </Typography.Title>
            </Col>

            <Row
              style={{
                justifyContent: 'flex-end',
              }}
            >
              <Form
                form={form}
                layout="inline"
                className="sort-form"
                component="div"
                initialValues={{ search: '', state: 'available_aps' }}
                size="small"
              >
                <Row>
                  <Col>
                    <Form.Item label="State" name="state" size="small">
                      <Select
                        style={{ width: 150 }}
                        onChange={handleChangFilter}
                      >
                        <Select.Option value="all">All</Select.Option>
                        {retrieverStateOptions.map(option => (
                          <Select.Option
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item label="Search" name="search" size="small">
                      <Input
                        onChange={handleChangeSearch}
                        type="text"
                        className="search-input"
                        placeholder="CMS ID, Full name, Contname"
                        style={{ width: 230 }}
                      />
                    </Form.Item>
                  </Col>
                  {userProfile.is_superuser && (
                    <Col>
                      <Form.Item
                        label="Supervisor CMS ID"
                        name="supervisor_cms_id"
                        size="small"
                        style={{ marginRight: 0 }}
                      >
                        <Input
                          onChange={handleChangeSupervisorID}
                          type="text"
                          className="search-input"
                          placeholder="Supervisor CMS ID"
                          style={{ width: 170 }}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Form>
            </Row>
          </Row>
          <Table
            className="table-with-editable-cells"
            columns={tableColumns}
            onLoadData={getRetrievers}
            formData={validateSearchParams(searchParams)}
            totalCount={count}
            totalData={retrieversList}
            loadingStatus={loadingStatus}
            entity={RETRIEVERS}
            pageSizeOptions={DEFAULT_FACILITY_PROFILE_PAGE_SIZE_OPTIONS}
            pageSize={DEFAULT_FACILITY_PROFILE_PAGE_SIZE}
            onPageChange={setPagination}
            rowKey="id"
          />
        </>
      </PageLayout>
    </Spin>
  )
}

export default RetrieversList
