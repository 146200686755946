import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { getSettings, resetMassDispatchState } from './actions'
import { IDLE_STATUS, LOADING_STATUS, SUCCESS_STATUS } from '../../../constants'

export const massDispatchAdapter = createEntityAdapter()
const initialState = massDispatchAdapter.getInitialState({
  status: IDLE_STATUS,
  count: 0,
})

const massDispatchSlice = createSlice({
  name: 'massDispatch',
  initialState,
  extraReducers: builder => {
    builder.addCase(resetMassDispatchState, state => {
      state.count = 0
      state.status = IDLE_STATUS
      massDispatchAdapter.removeAll(state)
    })
    builder.addCase(getSettings.pending, state => {
      state.status = LOADING_STATUS
    })
    builder.addCase(
      getSettings.fulfilled,
      (state, { payload: { results = [], count = 0 } }) => {
        state.count = count
        massDispatchAdapter.setAll(state, results)
        state.status = SUCCESS_STATUS
      }
    )
  },
})

const { reducer } = massDispatchSlice

export default reducer
