import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getPagination } from '../../../utils/functions'

import MassDispatchService from '../../../services/MassDispatchService'

export const getSettings = createAsyncThunk('massDispatch/getSettings', data =>
  getPagination(data, MassDispatchService.getSettings.bind(MassDispatchService))
)

export const updateSetting = createAsyncThunk(
  'massDispatch/updateSetting',
  async ({ id, data }) => {
    const response = await MassDispatchService.updateSetting(id, data)
    return response
  }
)

export const resetMassDispatchState = createAction('massDispatch/resetState')
