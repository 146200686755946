import React, { useRef } from 'react'
import { routes } from './routes'

const UserContext = React.createContext({
  currentUser: null,
  setCurrentUser: null,
  isUserActionAllowed: null,
})

export const UserProvider = ({ initialUser, children }) => {
  const currentUser = useRef(initialUser)
  const userPermissions = useRef(null)

  const isUserActionAllowed = action => {
    const user = currentUser.current
    const permissions = userPermissions.current || []

    if (action === 'is_superuser' && user?.is_superuser) {
      return true
    }

    if (user?.is_superuser) {
      return true
    }

    if (Array.isArray(action)) {
      return !!action.filter(item =>
        permissions.find(permission => permission.codename === item)
      ).length
    }

    return !!permissions?.find(item => item.codename === action)
  }

  const getUserProfile = () => currentUser.current

  const getAllowedRoute = () =>
    routes.find(
      route => isUserActionAllowed(route.permission) || !route.permission
    )

  return (
    <UserContext.Provider
      value={{
        setUserPermissions: data => {
          userPermissions.current = data
        },
        setCurrentUser: data => {
          currentUser.current = data
        },
        getUserProfile,
        isUserActionAllowed,
        getAllowedRoute,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => React.useContext(UserContext)
